<template>
    <div v-loading="$wait.is('damages.loading')" class="card card-f-h flex flex-col" element-loading-spinner="el-custom-spinner">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <ModalAddDamage
            :modalAddDamage="modalAddDamage"
            @refresh="getAll"
            @close="modalAddDamage = false"
        />

        <ModalEditDamage
            :modalVisible="modalEditDamage"
            :damageData="selectedDamage"
            @refresh="getAll"
            @close="modalEditDamage = false"
        />

        <div class="flex items-center justify-between border-b border-gray-300 pb-4 mb-5">
            <div class="flex">
                <div class="select">
                    <ClientsDropdown
                        @selected="getAll"
                    />
                </div>
                <div class="select pl-2">
                    <EmployeesDropdown
                        customClass="w-full"
                        @selected="getAll"
                    />
                </div>
                <el-select v-model="selectedStatus" placeholder="Status" class="pl-2" @change="changeSelectedStatus">
                    <el-option :label="$t('client.all')" :value="null" />
                    <el-option :label="$t('client.open')" value="0" />
                    <el-option :label="$t('client.solved')" value="1" />
                </el-select>
            </div>
            <el-button type="primary" @click="showModalAddDamage">
                {{ $t('common.add') }}
            </el-button>
        </div>

        <DamagesTable :damagesData="damagesData" @handleEdit="handleEdit" @handleDelete="handleDelete" />

        <NoDataInformation :data="noDataInformation" waitKey="damages.loading" />
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    components: {
        DamagesTable:      () => import(/* webpackChunkName: "components/DamagesTable" */ './components/DamagesTable'),
        ModalAddDamage:    () => import(/* webpackChunkName: "modals/ModalAddDamage" */ './modals/ModalAddDamage'),
        ModalEditDamage:   () => import(/* webpackChunkName: "modals/ModalEditDamage" */ './modals/ModalEditDamage'),
        EmployeesDropdown: () => import(/* webpackChunkName: "EmployeesDropdown" */ '@/components/EmployeesDropdown'),
        ClientsDropdown:   () => import(/* webpackChunkName: "ClientsDropdown" */ '@/components/ClientsDropdown'),
    },

    data() {
        return {
            noDataInformation: false,
            damagesData:       [],
            selectedDamage:    {},
            modalAddDamage:    false,
            modalEditDamage:   false,
            selectedStatus:    queryString.parse(window.location.search).status || null,
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('clients.damages'));
        this.$store.commit('setActiveMenuItem', '1-5');
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('damages.loading');
            const query = queryString.parse(window.location.search);

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/damages`, {
                params: {
                    client_uuid:   query.client,
                    employee_uuid: query.employee,
                    status:        query.status,
                },
            });

            this.damagesData = data;
            this.noDataInformation = data.length;
            this.$wait.end('damages.loading');
        },

        changeSelectedStatus() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.status = this.selectedStatus;
            this.$router.replace({ query }).catch(() => {});
            this.getAll();
        },

        showModalAddDamage() {
            this.modalAddDamage = true;
        },

        handleEdit(item) {
            this.selectedDamage = item;
            this.modalEditDamage = true;
        },

        handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/clients/damages/${uuid}`,
                actionType: 'destroy',
            });
        },
    },
};
</script>
